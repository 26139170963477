<template>
  <div class="btn-go-back">
    <!-- We have wrapper because ripple effect give position relative to this absolute positioned btn -->
    <!-- Hence due to that our btn get lost -->
    <b-button
      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
      variant="secondary"
      class="btn-icon"
      @click="goBack"
    >
      <feather-icon
        icon="ArrowLeftIcon"
        size="24"
      />
    </b-button>
  </div>
</template>

<script>
import { BButton } from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import router from '@/router'

export default {
  directives: {
    Ripple,
  },
  components: {
    BButton,
  },
  setup() {
    function goBack() {
      router.go(-1)
    }

    return { goBack }
  },

}
</script>

<style lang="scss" scoped>
.btn-go-back {
  position: fixed;
    bottom: 51px;
    right: 75px; // calc(50% - 22px);
    z-index: 99;

    opacity: 1;
    transition: all .5s ease;
}
</style>
